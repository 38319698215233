<template>
    <div id="multiaccounts-accounts-layouts" class="hide-scrollbar">
        <template v-for="account in filteredAccounts">
            <Account :account="account" @emitAccountId="emitSelectedAccountId" />
        </template>
    </div>
</template>

<script>
import Account from './account.vue'
export default {
    components: { Account },
    props: {
        searchInputValue: String,
        accounts: Object
    },
    data() {
        return {
            filteredAccounts: []
        }
    },
    methods: {
        emitSelectedAccountId($event) {
            this.$emit('emitSelectedAccountId', $event)
        },
        filterAccounts(searchInputValue = '') {
            const accounts = Object.values(this.accounts)

            const removeAccents = (str) => {
                return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            }

            if (searchInputValue != '') {
                this.filteredAccounts = accounts.filter((account) => removeAccents(account.account_name.toLowerCase()).includes(removeAccents(searchInputValue.toLowerCase())))
                return true
            }

            this.filteredAccounts = accounts
        }
    },
    created() {
        this.filterAccounts()
    },
    watch: {
        searchInputValue: function (searchInputValue) {
            this.filterAccounts(searchInputValue)
        }
    }
}
</script>

<style lang="scss">
#multiaccounts-accounts-layouts {
    padding-top: 48px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
    height: auto;
    grid-template-rows: 120px;
    margin-bottom: 40px;
}
</style>

<template>
    <div id="content" class="multiaccount">
        <Title />
        <template v-if="accounts && Object.values(accounts).length > 6">
            <SearchBar @emitSearchInputValue="setSearchInputValue" />
        </template>
        <Accounts :accounts="accounts" :searchInputValue="searchInputValue" @emitSelectedAccountId="accountLogin" />
    </div>
</template>

<script>
import Title from './title.vue'
import SearchBar from './searchbar.vue'
import Accounts from './accounts.vue'
export default {
    components: { Title, SearchBar, Accounts },
    computed: {
        employee() {
            const employee = this.$store.getters['login/getEmployeeMultiAccount']
            return employee
        },
        accounts() {
            return this.employee && typeof this.employee.accounts !== 'undefined' && this.employee.accounts ? this.employee.accounts : {}
        }
    },
    data() {
        return {
            searchInputValue: ''
        }
    },
    methods: {
        setSearchInputValue($event) {
            this.searchInputValue = $event
        },
        accountLogin($event) {
            this.$overlay.loading()
            const self = this
            const params = {
                id: $event,
                data: this.accounts[$event]
            }
            this.$store
                .dispatch('login/changeAccount', params)
                .then((response) => {
                    if (response) {
                        self.$store.commit('login/setEmployeeMultiaccount', false)
                        self.$router.push('/home')
                    } else {
                        console.error(response)
                    }
                    setTimeout(() => location.reload(), 500)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    },
    created() {
        if (!this.employee) {
            this.$router.push('/home')
        }
        this.$store.dispatch('navigation/setPage', { name: 'Multiaccount' })
        this.$store.dispatch('login/initMultiAccount')
    }
}
</script>

<style lang="scss">
.multiaccount {
    margin: auto;
    padding: 64px;
}
</style>

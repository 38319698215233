<template>
    <div id="multiaccounts-account-element" @click="emitAccountId">
        <div id="multiaccounts-account-image">
            <img :src="account.avatar" />
        </div>
        <div id="multiaccounts-account-name">{{ account.account_name }}</div>
    </div>
</template>

<script>
export default {
    props: {
        account: Object
    },
    methods: {
        emitAccountId() {
            this.$emit('emitAccountId', this.account.account_id)
        }
    }
}
</script>

<style lang="scss">
#multiaccounts-account-element {
    @include border-radius(4px);
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 5px #e3e7ed;
    min-width: 224px;
    min-height: 120px;
    width: 100%;
    height: 100%;

    #multiaccounts-account-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60%;
        img {
            max-width: 150px;
            max-height: 42px;
        }
    }

    #multiaccounts-account-name {
        @include font-size(md);
        height: 40%;
        display: flex;
        justify-content: center;
        font-family: $text-light;
        color: $color-black;
        text-align: center;
    }
}
</style>
